<template>
  <div class="searchResource filter-card-cont flex flex-col items-center justify-center space-y-4 overflow-hidden py-6 lg:px-6 lg:py-12 lg:px-20 border border-solid w-full">
    <div class="px-6 lg:p-0">
      <h4 class="text-2xl lg:text-4xl lg:text-center font-bold mb-4">What do you want to know?</h4>
      <vs-input
        icon="search"
        class="searchResource-input search-input w-full"
        @focusin="() => isInputFocus = true"
        @focusout="() => isInputFocus = false"
        @keyup.enter="handleSearch"
        placeholder="Search topics, webinars, documents and forms"
        v-model="filter.search"
      />
    </div>

    <div class="flex items-center justify-start lg:justify-center space-x-2 lg:space-x-4 w-full px-6 lg:px-0 overflow-x-auto">
      <button
        v-for="(action, i) in actions"
        type="border"
        class="searchResource-link flex-shrink-0 vs-button vs-button-border vs-button-primary rounded-full bg-white border border-solid text-primary font-medium"
        :key="i"
        :class="{ 'active-btn': filter.type === action.value }"
        @click="handleOnSelect(action.value)"
      >
        {{ action.label }}
      </button>
      <button
        type="border"
        class="searchResource-link flex-shrink-0 vs-button vs-button-border vs-button-primary rounded-full bg-white border border-solid text-primary font-medium"
        @click="howToGuideTab"
      >
        How-to Guides
      </button>
    </div>

    <Transition>
      <vs-button
        class="searchResource-btn search-btn w-11/12 lg:w-full mx-auto"
        v-show="isInputFocus || filter.type"
        @click="handleSearch">

        <span class="flex items-center justify-center space-x-2">
          <span>Explore</span>
          <vs-icon
            icon="arrow_right_alt"
            color="#fff">
          </vs-icon>
        </span>
      </vs-button>
    </Transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isInputFocus: false,
      filter: {
        search: "",
        type: null,
      },
    };
  },
  computed: {
    actions() {
      return this.$store.state.resourceHub.filters.types;
    }
  },
  methods: {
    handleSearch() {
      const userType = this.$route.path.split('/')[1]

      this.$router.push({
        name: `${userType}-search-resources`,
        query: this.filter
      });
    },

    handleOnSelect(value) {
      if (value === this.filter.type) this.filter.type = null;
      else this.filter.type = value
    },

    howToGuideTab() {
      const iframeView = localStorage.getItem("iframe-view");
      window.open("https://answers.freshclinics.com.au/knowledge", iframeView ? '_self' : '_blank');
    }
  },
  created() {
    const query = this.$route.query

    this.filter.search = query.search
    this.filter.type = query.type || null
  },
  watch: {
    "$route.query": function (val) {
      this.filter.search = val.search
      this.filter.type = val.type || null
    },
  }
};
</script>

<style lang="scss">
.searchResource {
  background-color: #fafafa;
  border-color:#e3ddd7;
  border-radius: 1.8rem;

  &-link {
    border-radius: 20px;
    background: rgba(255, 255, 255, 1) !important;
    border: 1px solid rgba(227, 221, 215, 1);

    &.active-btn {
      background: rgba(235, 247, 244, 1) !important;
      border: 1px solid rgba(132, 205, 184, 1);
    }
  }

  &-input {
    @media screen and (min-width: 992px) {
      & {
        width: 40rem !important;
      }
    }
  }

  &-btn {
    @media screen and (min-width: 992px) {
      & {
        width: 20rem !important;
      }
    }

    .vs-button--text {
      font-size: 1.1rem;
    }
  }

  .vs {
    &-icon {
      border-right: none;
      font-size: 1.3rem;
      color: #262928;
      top: 12px !important;
      left: 12px !important;
    }

    &-input {
      &--input.normal {
        font-size: 1.2rem !important;
      }
      &--placeholder {
        font-size: 1rem !important;
      }
    }

    &-con {
      &-input {
        &-label {
          width: var(--button-size);
        }
        &--input {
          &.hasIcon {
            padding: 1.2rem 1rem 1.2rem 3rem !important;
          }
        }
        &--placeholder {
          padding: 1.2rem !important;
          padding-left: 3rem !important;
        }
      }
    }
  }

  .v {
    &-enter {
      &-active {
        transition: opacity .5s ease;
      }
      &-from {
        opacity: 0;
      }
    }
    &-leave {
      &-active {
        transition: opacity .5s ease;
      }
      &-to {
        opacity: 0;
      }
    }
  }
}
</style>
